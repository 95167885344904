import { createSlice } from '@reduxjs/toolkit';
import { RESOLUTION_TYPE_DIMENSION } from '../../common/constants';
import { UIState } from '../../common/types/SliceTypes';
import { RootState } from '../store';

const initial: UIState = {
  metadata: RESOLUTION_TYPE_DIMENSION.HD,
  currentWidth: RESOLUTION_TYPE_DIMENSION.HD.width,
  currentHeight: RESOLUTION_TYPE_DIMENSION.HD.height,
  rowActiveHeight: {
    default: RESOLUTION_TYPE_DIMENSION.HD.headerLineHeight,
    custom: RESOLUTION_TYPE_DIMENSION.HD.headerLineHeight,
    changePer: 0,
  },
  rowInActiveHeight: {
    default: RESOLUTION_TYPE_DIMENSION.HD.headerLineHeight,
    custom: RESOLUTION_TYPE_DIMENSION.HD.headerLineHeight,
    changePer: 0,
  },
  fontSize: {
    default: RESOLUTION_TYPE_DIMENSION.HD.fontSize,
    custom: RESOLUTION_TYPE_DIMENSION.HD.fontSize,
    changePer: 0,
  },
  tableFontSize: {
    default: RESOLUTION_TYPE_DIMENSION.HD.fontSize,
    custom: RESOLUTION_TYPE_DIMENSION.HD.fontSize,
    changePer: 0,
  },
  lineHeight: {
    default: RESOLUTION_TYPE_DIMENSION.HD.headerLineHeight,
    custom: RESOLUTION_TYPE_DIMENSION.HD.headerLineHeight,
    changePer: 0,
  },
  cellPadding: {
    default: RESOLUTION_TYPE_DIMENSION.HD.padding,
    custom: RESOLUTION_TYPE_DIMENSION.HD.padding,
    changePer: 0,
  },
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState: initial,
  reducers: {
    setResolutionType: (state, value) => {
      return {
        ...state,
        metadata: value.payload,
      };
    },
    setCurrentWidth: (state, value) => {
      return {
        ...state,
        currentWidth: value.payload,
      };
    },
    setCurrentHeight: (state, value) => {
      return {
        ...state,
        currentHeight: value.payload,
      };
    },
    setCustomFontSize: (state, value) => {
      return {
        ...state,
        fontSize: {
          default: state.fontSize.default,
          changePer: (value.payload - state.fontSize.default) / state.fontSize.default,
          custom: value.payload,
        },
      };
    },
    setCustomTableFontSize: (state, value) => {
      return {
        ...state,
        tableFontSize: {
          default: state.fontSize.default,
          changePer: (value.payload - state.fontSize.default) / state.fontSize.default,
          custom: value.payload,
        },
      };
    },
    setDefaultFontSize: (state, value) => {
      return {
        ...state,
        fontSize: {
          custom: value.payload,
          default: value.payload,
          changePer: 0,
          appCustom: value.payload,
          tableCustom: value.payload,
        },
      };
    },
    setCustomLineHeight: (state, value) => {
      return {
        ...state,
        lineHeight: {
          default: state.lineHeight.default,
          changePer: (value.payload - state.lineHeight.default) / state.lineHeight.default,
          custom: value.payload,
        },
      };
    },
    setDefaultLineHeight: (state, value) => {
      return {
        ...state,
        lineHeight: {
          custom: value.payload,
          default: value.payload,
          changePer: 0,
        },
      };
    },
    setCustomCellPadding: (state, value) => {
      return {
        ...state,
        cellPadding: {
          default: state.cellPadding.default,
          changePer: (value.payload - state.cellPadding.default) / state.cellPadding.default,
          custom: value.payload,
        },
      };
    },
    setDefaultCellPadding: (state, value) => {
      return {
        ...state,
        cellPadding: {
          custom: value.payload,
          default: value.payload,
          changePer: 0,
        },
      };
    },
    setCustomRowActiveHeight: (state, value) => {
      return {
        ...state,
        rowActiveHeight: {
          custom: value.payload,
          default: state.metadata.activeRowHeight,
          changePer:
            (value.payload - state.metadata.activeRowHeight) / state.metadata.activeRowHeight,
        },
      };
    },
    setCustomRowInActiveHeight: (state, value) => {
      return {
        ...state,
        rowInActiveHeight: {
          custom: value.payload,
          default: state.metadata.activeRowHeight,
          changePer:
            (value.payload - state.metadata.activeRowHeight) / state.metadata.activeRowHeight,
        },
      };
    },
  },
});

export const {
  setResolutionType,
  setCurrentHeight,
  setCurrentWidth,
  setCustomCellPadding,
  setCustomLineHeight,
  setCustomFontSize,
  setCustomTableFontSize,
  setDefaultFontSize,
  setDefaultCellPadding,
  setDefaultLineHeight,
  setCustomRowActiveHeight,
  setCustomRowInActiveHeight,
} = uiSlice.actions;

export const selectResolutionMetadata = (state: RootState) => state.ui.metadata;

export const selectCurrentHeight = (state: RootState) => state.ui.currentHeight;

export const selectCurrentWidth = (state: RootState) => state.ui.currentWidth;

export const selectFontSize = (state: RootState) => state.ui.fontSize.custom;

export const selectTableFontSize = (state: RootState) => state.ui.tableFontSize.custom;

export const selectDefaultFontSize = (state: RootState) => state.ui.fontSize.default;

export const selectFontPer = (state: RootState) => state.ui.fontSize.changePer;

export const selectTableFontPerChange = (state: RootState) => state.ui.tableFontSize.changePer;

export const selectDashboardLogoMetadata = (state: RootState) =>
  state.ui.metadata.image_dimension.DASHBOARD_LOGO;

export const selectUploadDialogLogoMetadata = (state: RootState) =>
  state.ui.metadata.image_dimension.UPLOAD_DIALOG_LOGO;

export const selectUserIconMetadata = (state: RootState) => state.ui.metadata.image_dimension.USER;

export const selectTabActionMetadata = (state: RootState) =>
  state.ui.metadata.image_dimension.TAB_ACTION;

export const selectTabAddMetadata = (state: RootState) => state.ui.metadata.image_dimension.TAB_ADD;

export const selectTabViewMetadata = (state: RootState) =>
  state.ui.metadata.image_dimension.TABS_VIEW;

export const selectInActiveRowHeight = (state: RootState) => state.ui.rowInActiveHeight.default;

export const selectActiveRowHeight = (state: RootState) => state.ui.rowActiveHeight.default;

export const selectCustomInActiveRowHeight = (state: RootState) => state.ui.rowInActiveHeight.custom;

export const selectCustomActiveRowHeight = (state: RootState) => state.ui.rowActiveHeight.custom;

export const selectCustomLineHeight = (state: RootState) => state.ui.lineHeight.custom;

export const selectCustomCellPadding = (state: RootState) => state.ui.cellPadding.custom;

export const selectUnderHeaderHeight = (state: RootState) => state.ui.metadata.underHeaderHeight;

export const selectLineHeight = (state: RootState) => state.ui.metadata.headerLineHeight;

export const selectCellPadding = (state: RootState) => state.ui.metadata.padding;

export default uiSlice.reducer;
