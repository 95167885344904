import { createSlice } from "@reduxjs/toolkit";
import { AUTHOR, LEASE_STATUS } from "../../common/constants";
import { SelectedDocumentState } from "../../common/types/SliceTypes";
import { RootState } from "../store";

const initialState: SelectedDocumentState = {
  id: "",
  name: "",
  status: LEASE_STATUS.DUMMY,
  author: AUTHOR.SELECT_AUTHOR,
  market: "",
  notes: "",
};

// Slice for the Current Documents
export const selectedDocumentSlice = createSlice({
  name: "document",
  initialState: initialState,
  reducers: {
    setName: (state, value) => {
      return {
        ...state,
        name: value.payload,
      };
    },
    setID: (state, value) => {
      return {
        ...state,
        id: value.payload,
      };
    },
    setHTML: (state, value) => {
      return {
        ...state,
        html: value.payload,
      };
    },
    setValues: (state, value) => {
      return {
        ...state,
        values: value.payload,
      };
    },
    // setFieldValue: (state, value) => {
    //   const updatedValueIndex = state.values?.findIndex(
    //     (val) => val.key == value.payload.fieldCode
    //   );
    //   if (
    //     state.values != undefined &&
    //     updatedValueIndex != undefined &&
    //     updatedValueIndex >= 0
    //   ) {
    //     state.values[updatedValueIndex].value = value.payload.newValue;
    //     state.values[updatedValueIndex].prevValue = value.payload.oldValue;
    //   }
    // },
  },
});

export const { setID, setName, setHTML, setValues } =
  selectedDocumentSlice.actions;

export const selectDocumentID = (state: RootState) => state.document.id;
export const selectDocumentName = (state: RootState) => state.document.name;
export const selectDocumentAuthor = (state: RootState) => state.document.author;
export const selectDocumentHTML = (state: RootState) => state.document.html;
export const selectDocumentValues = (state: RootState) => state.document.values;

export default selectedDocumentSlice.reducer;
