import { useAuth0 } from '@auth0/auth0-react';
import Wrapper from './Wrapper';
import { useAxiosLoader } from './api/axios';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import { useEffect, useState } from 'react';
import { useAppDispatch } from './redux/hooks';
import { addNewUser, setUserInfo, verifyUserInfo } from './redux/reducers/userSlice';

const App = () => {
  const {
    isLoading,
    loginWithRedirect,
    isAuthenticated,
    error,
    user,
    getAccessTokenSilently,
    getIdTokenClaims,
    logout,
  } = useAuth0();
  const [loading] = useAxiosLoader();
  const [isUserVerified, setIsUserVerified] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleRedirect = async () => {
      if (error) {
        alert(error.message);
        console.error('Authentication failed:', error);
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
        return;
      }

      if (isLoading) {
        return;
      }

      if (!isAuthenticated) {
        loginWithRedirect();
        return;
      }

      try {
        const iDTokenClaims = await getIdTokenClaims();
        // Check if the user is already registered
        const isUserRegistered = await dispatch(verifyUserInfo(user?.email || ''));

        if (isUserRegistered?.payload?.data === null) {
          // User is not registered, proceed with registration
          const userRole =
            iDTokenClaims?.isLandlord === 'true'
              ? iDTokenClaims?.isLandlord === 'true' && iDTokenClaims?.isTenant === 'true'
                ? 'Both'
                : 'Landlord'
              : 'Tenant';
          const userInfo = {
            customerName: user?.name,
            email: user?.email,
            role: userRole,
          };
          await dispatch(addNewUser(userInfo));
          const registeredUserDetails = await dispatch(verifyUserInfo(user?.email || ''));
          dispatch(setUserInfo(registeredUserDetails.payload?.data));
          setIsUserVerified(true);
        } else {
          dispatch(setUserInfo(isUserRegistered.payload?.data));
          setIsUserVerified(true);
        }
      } catch (error) {
        console.error('Error while getting access token:', error);
        // Handle error
      }
    };

    handleRedirect();
  }, [isLoading, isAuthenticated, error, getAccessTokenSilently]);

  if (!isUserVerified)
    return (
      <CircleSpinnerOverlay
        loading={!isUserVerified}
        color='#404040'
        overlayColor='rgba(255,253,255,0.9)'
        zIndex={5000}
      />
    );
  return <>{isUserVerified && <Wrapper />}</>;
};

export default App;
