import classNames from 'classnames';
import React, { useState } from 'react';
import { contextMenu } from 'react-contexify';
import { EditText } from 'react-edit-text';
import {
  CUSTOM_COLUMNS,
  CUSTOM_COLUMN_KEY,
  STATUS_FILTER_MENU_ID,
} from '../../../../common/constants';
import { FieldEntity, FieldUpdatePayload } from '../../../../common/types/EntityTypes';
import {
  CustomHeaderParams,
  EditTextPayload,
} from '../../../../common/types/dashboard/DashboardUITypes';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  selectShowNotes,
  selectShowStatus,
  toggleNotes,
  toggleStatus,
} from '../../../../redux/reducers/dashboardSlice';
import { toggleFieldPin, updateFieldAttribute } from '../../../../redux/reducers/metadataSlice';
import { selectMenu, setMenu, setSettings } from '../../../../redux/reducers/popupSlice';
import { selectResolutionMetadata } from '../../../../redux/reducers/uiSlice';
import {
  mapFieldUpdatePayloadToOfflineFieldUpdate,
} from '../../../../common/types/Mapper';
import { updateFieldMetadata } from '../../../../db/fieldDBAction';
import CollapseLeftArrow from '../../../../assets/images/dashboard/table/CollapseLeft.svg';
import CollapseRightArrow from '../../../../assets/images/dashboard/table/CollapseRight.svg';

function TableHeader(props: CustomHeaderParams) {
  const dispatch = useAppDispatch();

  const showNotes = useAppSelector(selectShowNotes);
  const showStatus = useAppSelector(selectShowStatus);
  const dashboardData = useAppSelector(selectResolutionMetadata);

  const selectedMenu = useAppSelector(selectMenu);
  const [editHeaderText, setEditHeaderText] = useState(false);

  function displayMenu(e: React.MouseEvent<HTMLElement>) {
    if (e.currentTarget.offsetParent != null && e.currentTarget.offsetParent != undefined) {
      let parentDoc = e.currentTarget.offsetParent?.getBoundingClientRect();
      dispatch(setMenu(STATUS_FILTER_MENU_ID));
      contextMenu.show({
        id: selectedMenu,
        event: e,
        position: {
          x: parentDoc.left + parentDoc.width + 5,
          y: parentDoc.top + 5,
        },
      });
    }
  }

  function handleNotesVisiblity() {
    const newNotesVisibility = !showNotes;
    props.columnApi
      .getColumns()
      ?.filter(
        (col) =>
          col.isPinnedRight() &&
          (col.getColDef().cellRendererParams.customData.code == CUSTOM_COLUMN_KEY.NOTES ||
            col.getColDef().cellRendererParams.customData.code == CUSTOM_COLUMN_KEY.NOTES_HIDDEN),
      )
      .forEach((col) => {
        if (col.getColDef().cellRendererParams.customData.code == CUSTOM_COLUMN_KEY.NOTES) {
          if (newNotesVisibility) {
            props.columnApi.setColumnVisible(col.getColId(), true);
          } else {
            props.columnApi.setColumnVisible(col.getColId(), false);
          }
        } else if (
          col.getColDef().cellRendererParams.customData.code == CUSTOM_COLUMN_KEY.NOTES_HIDDEN
        ) {
          if (newNotesVisibility) {
            props.columnApi.setColumnVisible(col.getColId(), false);
          } else {
            props.columnApi.setColumnVisible(col.getColId(), true);
          }
        }
      });
    dispatch(toggleNotes());
  }

  function handleStatusVisiblity() {
    const statusVisibility = !showStatus;
    props.columnApi
      .getColumns()
      ?.filter(
        (col) =>
          col.isPinnedLeft() &&
          (col.getColDef().cellRendererParams.customData.code == CUSTOM_COLUMN_KEY.STATUS ||
            col.getColDef().cellRendererParams.customData.code == CUSTOM_COLUMN_KEY.STATUS_HIDDEN),
      )
      .forEach((col) => {
        if (col.getColDef().cellRendererParams.customData.code == CUSTOM_COLUMN_KEY.STATUS) {
          if (statusVisibility) {
            props.columnApi.setColumnVisible(col.getColId(), true);
          } else {
            props.columnApi.setColumnVisible(col.getColId(), false);
          }
        } else if (
          col.getColDef().cellRendererParams.customData.code == CUSTOM_COLUMN_KEY.STATUS_HIDDEN
        ) {
          if (statusVisibility) {
            props.columnApi.setColumnVisible(col.getColId(), false);
          } else {
            props.columnApi.setColumnVisible(col.getColId(), true);
          }
        }
      });
    dispatch(toggleStatus());
  }

  const handleSave = (editTextPayload: EditTextPayload) => {
    let requestPayload = {} as FieldUpdatePayload;
    requestPayload.code = props.customData.code;
    requestPayload.name =
      editTextPayload.value !== '' ? editTextPayload.value : props.customData.name;
    requestPayload.order = props.customData.order;
    requestPayload.isPinned = props.customData.pinned;
    requestPayload.isVisible = props.customData.visible;
    requestPayload.widthChange = props.customData.widthChange;
    if (editTextPayload.value !== '') {
      updateFieldMetadata(mapFieldUpdatePayloadToOfflineFieldUpdate(requestPayload, false)).then(
        () => {
          dispatch(updateFieldAttribute(requestPayload));
        },
      );
    }
    setEditHeaderText(false);
  };

  const handleColumnPin = (toPin: boolean) => {
    dispatch(toggleFieldPin(props.customData.id));
    props.columnApi.applyColumnState({
      state: [{ colId: props.customData.name, pinned: toPin ? 'left' : null }],
    });

    dispatch(
      updateFieldAttribute({
        code: props.customData.code,
        isPinned: toPin,
        isVisible: props.customData.visible,
        name: props.customData.name,
        order: props.customData.order,
      } as FieldUpdatePayload),
    );
  };

  function getHeader(header: FieldEntity) {
    if (header.code == CUSTOM_COLUMNS.PIN.key) {
      return <span></span>;
    } else if (header.code == CUSTOM_COLUMNS.NOTES_HIDDEN.key) {
      return (
        <div style={{ flexShrink: 0 }} className='flex fit-container notes-hidden'>
          <img
            width={dashboardData.image_dimension.NOTES.widthPX}
            height={dashboardData.image_dimension.NOTES.heightPX}
            src={CollapseLeftArrow}
            onClick={handleNotesVisiblity}></img>
        </div>
      );
    } else if (header.code == CUSTOM_COLUMNS.NOTES.key) {
      return (
        <div style={{ flexShrink: 0 }} className='flex fit-container notes-values-text'>
          <img
            className='img-no-shirnk'
            width={dashboardData.image_dimension.NOTES.widthPX}
            height={dashboardData.image_dimension.NOTES.heightPX}
            src={CollapseRightArrow}
            onClick={handleNotesVisiblity}></img>
          {showNotes && <div className='flex fit-container header-text'>{header.name}</div>}
        </div>
      );
    } else if (header.code == CUSTOM_COLUMNS.STATUS_HIDDEN.key) {
      return (
        <div style={{ flexShrink: 0 }} className='flex fit-container'>
          <img
            width={dashboardData.image_dimension.NOTES.widthPX}
            height={dashboardData.image_dimension.NOTES.heightPX}
            src={CollapseRightArrow}
            onClick={handleStatusVisiblity}></img>
        </div>
      );
    } else if (header.code == CUSTOM_COLUMNS.STATUS.key) {
      return (
        <div className='flex fit-container header-text header-text-align lease-filter-holder status-collapse'>
          {/* {header.name} */}
          <div>STATUS</div>
          {/* {selectedMenu === STATUS_FILTER_MENU_ID && (
            <img src={headerFilterActive} onClick={() => dispatch(setMenu(NONE_MENU_ID))} />
          )} */}
          {/* {selectedMenu !== STATUS_FILTER_MENU_ID && (
            <img src={headerFilterInActive} onClick={(e) => displayMenu(e)} />
          )} */}
          <div className='img-container'>
            <img
              className='img-no-shirnk'
              width={dashboardData.image_dimension.NOTES.widthPX}
              height={dashboardData.image_dimension.NOTES.heightPX}
              src={CollapseLeftArrow}
              onClick={handleStatusVisiblity}></img>
          </div>
        </div>
      );
    } else if (header.code == CUSTOM_COLUMNS.CHILDREN.key) {
      return (
        <img
          className='img-no-shirnk'
          width={dashboardData.image_dimension.SETTINGS.widthPX}
          height={dashboardData.image_dimension.SETTINGS.heightPX}
          src={dashboardData.image_dimension.SETTINGS.image}
          onClick={() => dispatch(setSettings(true))}></img>
      );
    } else if (
      // header.code == CUSTOM_COLUMNS.MARKET.key ||
      // header.code == CUSTOM_COLUMNS.AUTHOR.key ||
      header.name == CUSTOM_COLUMNS.LOI_DATE.title
    ) {
      return <div className='flex fit-container header-text header-text-align'>{header.name}</div>;
    }

    return (
      <div className='flex' style={{ gap: '5px', alignItems: 'baseline' }}>
        {/* <EditText
          name={header.code}
          className='header-text header-no-padding-margin'
          inputClassName='header-text input-text'
          style={{ padding: '5px' }}
          onSave={handleSave}
          value={header.name}
          defaultValue={header.name}></EditText> */}
        {editHeaderText ? (
          <EditText
            name={header.code}
            className='header-text header-no-padding-margin'
            inputClassName='header-text input-text'
            style={{ padding: '5px' }}
            onSave={handleSave}
            defaultValue={header.name}></EditText>
        ) : (
          <span
            className='header-text header-no-padding-margin'
            onClick={() => setEditHeaderText(true)}>
            {props.customData.name}
          </span>
        )}
        {/* <img
          className="img-no-shirnk"
          onClick={(e) => handleColumnPin(!header.pinned)}
          width={dashboardData.image_dimension.COLUMN_PIN.widthPX}
          src={
            header.pinned
              ? dashboardData.image_dimension.COLUMN_PIN.unpinnedImage
              : dashboardData.image_dimension.COLUMN_PIN.pinnedImage
          }
        ></img> */}
      </div>
    );
  }
  return (
    <>
      <div
        className={classNames(
          'flex-auto-justify-end',
          'fit-container',
          'ag-cell-font-common',

          // {
          //   "flex-h-align-center":
          //     props.customData?.code == CUSTOM_COLUMNS.STATUS.key ||
          //     props.customData?.code == CUSTOM_COLUMNS.CHILDREN.key,
          // }
        )}>
        {getHeader(props.customData)}
      </div>
    </>
  );
}
export default TableHeader;
