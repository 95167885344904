import './TabModal.scss';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { getUpdatedCustomFontSize } from '../../../../../common/util';
import {
  getTabNameAlias,
  moveTabAlias,
  selectSelectedTabInfo,
  selectTabAliasToBeModified,
  selectTabMessage,
  setCreateTabAliasNameFlag,
  setShowTabSettingsModal,
  setTabAliasList,
  setTabAliasToBeModified,
  setTabMessage,
} from '../../../../../redux/reducers/settingsSlice';

function TabMessage(props: {
  isActionable: boolean
}) {
  const dispatch = useAppDispatch();
  const tabMessage = useAppSelector(selectTabMessage);
  const selectedTabInfo = useAppSelector(selectSelectedTabInfo);
  const tabAliasToBeModified = useAppSelector(selectTabAliasToBeModified);

  const customStyle = {
    display: 'flex',
    '--custom-gs-rc-slider-mark-text': getUpdatedCustomFontSize('--custom-gs-rc-slider-mark-text'),
    '--custom-gs-label-font-size': getUpdatedCustomFontSize('--custom-gs-label-font-size'),
  };

  const handleCancelTab = () => {
    dispatch(setShowTabSettingsModal(''));
    dispatch(setTabMessage(''));
  };

  const handleMoveTab = () => {
    dispatch(
      moveTabAlias({ targetTab: selectedTabInfo.id, aliasTab: tabAliasToBeModified.externalId }),
    ).then((res) => {
      if (res.payload?.data) {
        dispatch(setShowTabSettingsModal(''));
        dispatch(setTabAliasToBeModified({}));
        dispatch(setCreateTabAliasNameFlag(false));
        dispatch(getTabNameAlias(selectedTabInfo?.id)).then((res) => {
          if (res?.payload?.data?.length > 0) {
            dispatch(setTabAliasList(res?.payload?.data));
          }
        });
      }
    });
  };

  return (
    <div className='dialog-container tab-message' style={customStyle}>
      <div className='dc-header'>
        <div className="dc-tab-message-text" dangerouslySetInnerHTML={{ __html: tabMessage }} />
      </div>
      {props.isActionable === true ? <div className='dc-footer'>
        <button className='dc-btn dc-cancel' onClick={handleCancelTab}>
          Cancel
        </button>
        <button className='dc-btn dc-yes' onClick={handleMoveTab}>
          Yes
        </button>
      </div> : <div className='dc-footer'>
        <button className='dc-btn dc-yes' onClick={handleCancelTab}>
          OK
        </button>
      </div>}
    </div>
  );
}
export default TabMessage;
