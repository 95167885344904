import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userState } from "../../common/types/SliceTypes";
import { RootState } from "../store";
import { AXIOS } from '../../api/axios';
import { User } from '@auth0/auth0-react';
import { AxiosRequestConfig } from 'axios';

const initialState: userState = {
  id: "",
  name: "",
  role: "",
  token: "",
  email: "",
  externalId: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUserInfo: (state, value) => {
      return {
        ...state,
        id: value.payload?.id,
        name: value.payload?.customerName,
        role: value.payload?.role,
        externalId: value.payload?.externalId,
        email: value.payload?.email,
      };
    },
    setUserToken: (state, value) => {
      return {
        ...state,
        token: value.payload,
      };
    },
  },
});

export const {
  setUserInfo,
  setUserToken,
} = userSlice.actions;

export const selectUserID = (state: RootState) => state.user.externalId;
export const selectUserName = (state: RootState) => state.user.name;
export const selectUserRole = (state: RootState) => state.user.role;
export const selectUserToken = (state: RootState) => state.user.token;
export const selectUserEmail = (state: RootState) => state.user.email;

export default userSlice.reducer;

export const addNewUser = createAsyncThunk(
  "/user",
  async (userInfo: any, { getState }) => {
    const s = getState() as RootState;
    let url = process.env.REACT_APP_API_BASE_URL + "user";
    try {
      const response = await AXIOS.post(url, userInfo);
      s.user.externalId = response.data?.data;
      s.user.id = response.data?.data;
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getUserInfo = createAsyncThunk(
  "/user",
  async (userId: string, { getState }) => {
    const s = getState() as RootState;
    let url = process.env.REACT_APP_API_BASE_URL + "user/" + userId;
    try {
      const response = await AXIOS.get(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

export const verifyUserInfo = createAsyncThunk(
  "/userVerify",
  async (email: string, { getState }) => {
    const s = getState() as RootState;
    let url = process.env.REACT_APP_API_BASE_URL + "user/email/" + email;
    let config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    try {
      const response = await AXIOS.get(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);