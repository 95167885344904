import { Item } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectMarkets } from "../../../redux/reducers/metadataSlice";
import "./Market.scss";
import {
  selectCurrentTab,
  selectSelectedDocumentId,
  selectSelectedDocumentMarket,
  updateDocumentMarket,
} from "../../../redux/reducers/dashboardSlice";
import {
  updateMarketForDocumentID,
  updatePersistanceForDocumentID,
} from "../../../db/documentDBAction";
import { updateMarketForDocument } from "../../../redux/reducers/documentsDataSlice";
import classNames from "classnames";
function Market() {
  const dispatch = useAppDispatch();
  const markets = useAppSelector(selectMarkets);
  const selectedDocumentId = useAppSelector(selectSelectedDocumentId);
  const selectedClientId = useAppSelector(selectCurrentTab);
  const selectedMarket = useAppSelector(selectSelectedDocumentMarket);
  console.log(selectedMarket);

  const handleMarketUpdate = (marketExternalId: string) => {
    updateMarketForDocumentID(selectedDocumentId, marketExternalId)?.then(
      () => {
        dispatch(
          updateMarketForDocument({
            clientId: selectedClientId,
            documentId: selectedDocumentId,
            marketExternalId: marketExternalId,
          })
        );
        dispatch(
          updateDocumentMarket({
            documentId: selectedDocumentId,
            marketExternalId: marketExternalId,
          })
        ).then(() => {
          updatePersistanceForDocumentID(selectedDocumentId, true);
        });
      }
    );
  };
  return (
    <section className="grid-container menu-market-grid">
      {markets.map((market, index) => {
        return (
          <Item
            className={classNames({
              "market-selection": selectedMarket === market.externalId,
            })}
            key={market.externalId}
            id={market.externalId}
            onClick={(event) => handleMarketUpdate(market.externalId)}
          >
            <span className={classNames("menu-status-text")}>
              {market.name}
            </span>
          </Item>
        );
      })}
    </section>
  );
}

export default Market;
