import { createSlice } from "@reduxjs/toolkit";
import { LEASE_STATUS } from "../../common/constants";
import { RootState } from "../store";

export interface LeaseStatusFilter {
  name: string;
  text: string;
  selected: boolean;
}

let initialState = [
  {
    name: LEASE_STATUS.DUMMY,
    text: "",
    selected: true,
  },
  {
    name: LEASE_STATUS.NONE,
    text: "None",
    selected: true,
  },
  {
    name: LEASE_STATUS.ACTIVE,
    text: "Active",
    selected: true,
  },
  {
    name: LEASE_STATUS.IN_LEASE,
    text: "In Lease",
    selected: true,
  },
  {
    name: LEASE_STATUS.DEAD_DEAL,
    text: "Dead Deal",
    selected: true,
  },
];

export const leaseStatusSlice = createSlice({
  name: "leaseStatus",
  initialState: initialState,
  reducers: {
    toggleLeaseStatus: (state, value) => {
      const index = state.findIndex((filter) => filter.name == value.payload);
      state[index].selected = !state[index].selected;
    },
  },
});

export const { toggleLeaseStatus } = leaseStatusSlice.actions;

export const selectLeaseStatus = (state: RootState) => state.leaseStatus;

export default leaseStatusSlice.reducer;
