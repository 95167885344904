import './TabModal.scss';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { getUpdatedCustomFontSize } from '../../../../../common/util';
import { ClientEntity } from '../../../../../common/types/EntityTypes';
import { useEffect, useState } from 'react';
import { selectClients } from '../../../../../redux/reducers/metadataSlice';
import {
  moveTabAlias,
  selectSelectedTabInfo,
  selectTabAliasToBeModified,
  setSelectedTabInfo,
  setShowTabSettingsModal,
  setTabAliasToBeModified,
} from '../../../../../redux/reducers/settingsSlice';
import classNames from 'classnames';

function TabListDialog() {
  const dispatch = useAppDispatch();
  const [tabTarget, setTabTarget] = useState({} as ClientEntity);
  const tabAliasToBeModified = useAppSelector(selectTabAliasToBeModified);
  const tabClients = useAppSelector(selectClients);
  const selectedTabInfo = useAppSelector(selectSelectedTabInfo);
  useEffect(() => {
    if (tabClients?.length > 0)
      setTabTarget(tabClients.filter((e) => e?.id !== selectedTabInfo.id)[0]);
  }, [tabClients]);

  const customStyle = {
    display: 'flex',
    '--custom-gs-rc-slider-mark-text': getUpdatedCustomFontSize('--custom-gs-rc-slider-mark-text'),
    '--custom-gs-label-font-size': getUpdatedCustomFontSize('--custom-gs-label-font-size'),
  };

  const handleMoveTab = () => {
    dispatch(
      moveTabAlias({ targetTab: tabTarget.id, aliasTab: tabAliasToBeModified.externalId }),
    ).then((res) => {
      if (res.payload?.data) {
        dispatch(setShowTabSettingsModal(''));
        dispatch(setTabAliasToBeModified({}));
        dispatch(setSelectedTabInfo(tabTarget));
      }
    });
  };

  const handleCancelTab = () => {
    dispatch(setTabAliasToBeModified({}));
    dispatch(setShowTabSettingsModal(''));
  };

  return (
    <div className='dialog-container tab-list' style={customStyle}>
      <div className='dc-header'>
        <p>Move the Tenant Name Alias to</p>
      </div>
      <div className='dc-body'>
        <div className='dc-move-list-container'>
          <div className='move-tab-list'>
            {tabClients &&
              tabClients.map((tab) => {
                return (
                  selectedTabInfo.id !== tab?.id && (
                    <div
                      key={tab?.id}
                      className={classNames('move-tab-list-item', {
                        selected: tab?.id === tabTarget?.id,
                      })}
                      onClick={() => setTabTarget(tab)}>
                      {tab?.name}
                    </div>
                  )
                );
              })}
          </div>
        </div>
      </div>
      <div className='dc-footer'>
        <button className='dc-btn dc-cancel' onClick={handleCancelTab}>
          Cancel
        </button>
        <button className='dc-btn dc-confirm' onClick={handleMoveTab}>
          Yes
        </button>
      </div>
    </div>
  );
}
export default TabListDialog;
