import './TabModal.scss';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { getUpdatedCustomFontSize } from '../../../../../common/util';
import { ClientEntity } from '../../../../../common/types/EntityTypes';
import { useEffect, useState } from 'react';
import { selectClients, setClients } from '../../../../../redux/reducers/metadataSlice';
import checkbox from '../../../../../assets/images/dialog/settings/checkbox.svg';
import checkboxSelected from '../../../../../assets/images/dialog/settings/checkboxSelected.svg';
import {
  deleteTabByMovingLOI,
  //   deleteTabByMovingLOI,
  deleteTabName,
  selectSelectedTabInfo,
  selectTabToBeDeleted,
  setSelectedTabInfo,
  setShowTabSettingsModal,
  setTabToBeDeleted,
} from '../../../../../redux/reducers/settingsSlice';
import classNames from 'classnames';

function DeleteConfirmDialog() {
  const dispatch = useAppDispatch();
  const [toBeMovedTab, setToBeMovedTab] = useState({} as ClientEntity);
  const toBeDeletedTab = useAppSelector(selectTabToBeDeleted);
  const tabClients = useAppSelector(selectClients);
  const [operationToBeDone, setOperationToBeDone] = useState('deleteAll');
  const selectedTabInfo = useAppSelector(selectSelectedTabInfo);
  useEffect(() => {
    if (tabClients?.length > 0)
      setToBeMovedTab(tabClients.filter((e) => e.id !== toBeDeletedTab?.id)[0]);
  }, [tabClients]);

  const customStyle = {
    display: 'flex',
    '--custom-gs-rc-slider-mark-text': getUpdatedCustomFontSize('--custom-gs-rc-slider-mark-text'),
    '--custom-gs-label-font-size': getUpdatedCustomFontSize('--custom-gs-label-font-size'),
  };

  const handleDeleteTab = () => {
    if (operationToBeDone === 'deleteAll') {
      dispatch(deleteTabName(toBeDeletedTab)).then((res) => {
        if (res.payload.data !== null) {
          dispatch(setClients(tabClients.filter((e) => e.id !== toBeDeletedTab.id)));
          dispatch(setTabToBeDeleted({}));
          dispatch(setShowTabSettingsModal(''));
        }
      });
    } else {
      dispatch(deleteTabByMovingLOI(toBeMovedTab)).then((res) => {
        if (res?.payload?.data) {
          dispatch(setClients(tabClients.filter((e) => e.id !== selectedTabInfo?.id)));
          dispatch(setSelectedTabInfo(toBeMovedTab));
          dispatch(setTabToBeDeleted({}));
          dispatch(setShowTabSettingsModal(''));
        }
      });
    }
  };

  const handleCancel = () => {
    dispatch(setShowTabSettingsModal(''));
    dispatch(setTabToBeDeleted({}));
  };

  return (
    <div className='dialog-container' style={customStyle}>
      <div className='dc-header'>
        <div>Permanently remove the</div>
        <span className='dc-highlight'> {toBeDeletedTab?.name} </span>
        <span>Tab and</span>
      </div>
      <div className='dc-body'>
        <div className='dc-input'>
          <img
            src={operationToBeDone === 'deleteAll' ? checkboxSelected : checkbox}
            alt='Select'
            className='dc-input-radio'
            onClick={() => setOperationToBeDone('deleteAll')}
          />
          <label htmlFor='deleteAll'>
            <span>Delete All LOIs in the</span>
            <span className='dc-highlight'> {toBeDeletedTab?.name} </span>
            <span>Tab</span>
          </label>
        </div>
        <div className='dc-input'>
          <img
            src={operationToBeDone === 'moveAll' ? checkboxSelected : checkbox}
            alt='Select'
            className='dc-input-radio'
            onClick={() => setOperationToBeDone('moveAll')}
          />
          <label htmlFor='moveAll'>
            <span>Move All LOIs to the</span>
            <span className='dc-highlight'> {toBeMovedTab?.name} </span>
            <span>Tab</span>
          </label>
        </div>
        <div className='dc-move-list-container'>
          <div className='move-tab-list'>
            {tabClients &&
              tabClients.map((tab) => {
                return (
                  tab?.id !== toBeDeletedTab?.id && (
                    <div
                      key={tab?.id}
                      onClick={() => setToBeMovedTab(tab)}
                      className={classNames('move-tab-list-item', {
                        selected: tab?.id === toBeMovedTab?.id,
                      })}>
                      {tab?.name}
                    </div>
                  )
                );
              })}
          </div>
        </div>
      </div>
      <div className='dc-footer'>
        <button className='dc-btn dc-cancel' onClick={handleCancel}>
          Cancel
        </button>
        <button className='dc-btn dc-confirm' onClick={handleDeleteTab}>
          Yes
        </button>
      </div>
    </div>
  );
}
export default DeleteConfirmDialog;
