import { Item } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectClients } from "../../../redux/reducers/metadataSlice";
import {
  setDeSelectDocument,
  setSelectedTab,
} from "../../../redux/reducers/dashboardSlice";

function TabList() {
  const dispatch = useAppDispatch();
  const clientsList = useAppSelector(selectClients);

  function handleTab(tabId: string) {
    dispatch(setSelectedTab(tabId));
    dispatch(setDeSelectDocument());
  }
  return (
    <section>
      {clientsList.map((client, index) => {
        return (
          <Item
            key={client.id}
            id={client.id}
            onClick={() => handleTab(client.id)}
          >
            <span className="menu-status-text">{client.name}</span>
          </Item>
        );
      })}
    </section>
  );
}
export default TabList;
