import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { settingsState } from '../../common/types/SliceTypes';
import { RootState } from '../store';
import { AXIOS } from '../../api/axios';
import { ADD_CLIENT, API_CLIENT_NAME, API_VARIABLE_USER_ID } from '../../api/api-constants';
import { ClientAlias, ClientEntity, GeneralSettingsEntity, MarketEntity } from '../../common/types/EntityTypes';

const initialState: settingsState = {
  selectedTabInfo: {} as ClientEntity,
  selectedTabNameId: '',
  createTabFlag: false,
  createTabAliasFlag: false,
  updateTabFlag: false,
  updateTabAliasFlag: false,
  tabToBeDeleted: {} as ClientEntity,
  showTabSettingsModal: '',
  tabAliasList: [],
  tabAliasToBeModified: {} as ClientAlias,
  tabMessage: '',
  generalSettings: [],
  userAppFontSize: {} as GeneralSettingsEntity,
  userTableFontSize: {} as GeneralSettingsEntity,
  userCellPaddingSize: {} as GeneralSettingsEntity,
  userLineHeightSize: {} as GeneralSettingsEntity,
  userActiveRowHeight: {} as GeneralSettingsEntity,
  userInActiveRowHeight: {} as GeneralSettingsEntity,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    setSelectedTabInfo: (state, value) => {
      return {
        ...state,
        selectedTabInfo: value.payload,
      };
    },
    setSelectedTabId: (state, value) => {
      return {
        ...state,
        selectedTabNameId: value.payload,
      };
    },
    setCreateTabNameFlag: (state, value) => {
      return {
        ...state,
        createTabFlag: value.payload,
      };
    },
    setCreateTabAliasNameFlag: (state, value) => {
      return {
        ...state,
        createTabAliasFlag: value.payload,
      };
    },
    setUpdateTabNameFlag: (state, value) => {
      return {
        ...state,
        updateTabFlag: value.payload,
      };
    },
    setUpdateTabAliasNameFlag: (state, value) => {
      return {
        ...state,
        updateTabAliasFlag: value.payload,
      };
    },
    setTabToBeDeleted: (state, value) => {
      return {
        ...state,
        tabToBeDeleted: value.payload,
      };
    },
    setTabAliasList: (state, value) => {
      return {
        ...state,
        tabAliasList: value.payload,
      };
    },
    setGeneralSettings: (state, value) => {
      return {
        ...state,
        generalSettings: value.payload,
        userAppFontSize: value.payload[0],
        userTableFontSize: value.payload[1],
        userCellPaddingSize: value.payload[2],
        userActiveRowHeight: value.payload[3],
        userInActiveRowHeight: value.payload[4],
        userLineHeightSize: value.payload[5],
      };
    },
    setTabAliasToBeDeleted: (state, value) => {
      return {
        ...state,
        tabAliasToBeDeleted: value.payload,
      };
    },
    setTabAliasToBeModified: (state, value) => {
      return {
        ...state,
        tabAliasToBeModified: value.payload,
      };
    },
    setShowTabSettingsModal: (state, value) => {
      return {
        ...state,
        showTabSettingsModal: value.payload,
      };
    },
    setTabMessage: (state, value) => {
      return {
        ...state,
        tabMessage: value.payload,
      };
    },
    setUserAppFontSize: (state, value) => {
      return {
        ...state,
        userAppFontSize: value.payload
      }
    },
    setUserTableFontSize: (state, value) => {
      return {
        ...state,
        userTableFontSize: value.payload
      }
    },
    setUserCellPaddingSize: (state, value) => {
      return {
        ...state,
        userCellPaddingSize: value.payload
      }
    },
    setUserLineHeightSize: (state, value) => {
      return {
        ...state,
        userLineHeightSize: value.payload
      }
    },
    setUserActiveRowHeight: (state, value) => {
      return {
        ...state,
        userActiveRowHeight: value.payload
      }
    },
    setUserInActiveRowHeight: (state, value) => {
      return {
        ...state,
        userInActiveRowHeight: value.payload
      }
    }
  },
});

export const {
  setSelectedTabInfo,
  setSelectedTabId,
  setCreateTabNameFlag,
  setCreateTabAliasNameFlag,
  setUpdateTabAliasNameFlag,
  setUpdateTabNameFlag,
  setTabToBeDeleted,
  setShowTabSettingsModal,
  setTabAliasList,
  setTabAliasToBeModified,
  setTabMessage,
  setGeneralSettings,
  setUserAppFontSize,
  setUserTableFontSize,
  setUserCellPaddingSize,
  setUserLineHeightSize,
  setUserActiveRowHeight,
  setUserInActiveRowHeight,
} = settingsSlice.actions;

export const selectSelectedTabInfo = (state: RootState) => state.settings.selectedTabInfo;
export const selectSelectedTabNameId = (state: RootState) => state.settings.selectedTabNameId;
export const selectCreateTabFlag = (state: RootState) => state.settings.createTabFlag;
export const selectCreateTabAliasFlag = (state: RootState) => state.settings.createTabAliasFlag;
export const selectTabToBeDeleted = (state: RootState) => state.settings.tabToBeDeleted;
export const selectTabAliasList = (state: RootState) => state.settings.tabAliasList;
export const selectTabAliasToBeModified = (state: RootState) => state.settings.tabAliasToBeModified;
export const selectShowTabSettingsModal = (state: RootState) => state.settings.showTabSettingsModal;
export const selectTabMessage = (state: RootState) => state.settings.tabMessage;

export const selectUserAppFontSize = (state: RootState) => state.settings.userAppFontSize;
export const selectUserTableFontSize = (state: RootState) => state.settings.userTableFontSize
export const selectUserLineHeight = (state: RootState) => state.settings.userLineHeightSize;
export const selectUserCellPadding = (state: RootState) => state.settings.userCellPaddingSize;
export const selectUserActiveRowHeight = (state: RootState) => state.settings.userActiveRowHeight;
export const selectUserInactiveRowHeight = (state: RootState) => state.settings.userInActiveRowHeight;

export default settingsSlice.reducer;

export const getTabNames = createAsyncThunk('client/alias/', async (tab: string, { getState }) => {
  const state = getState() as RootState;
  let url = process.env.REACT_APP_API_BASE_URL + 'client/' + state.user.externalId;
  try {
    const response = await AXIOS.get(url);
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const getTabNameAlias = createAsyncThunk(
  'client/alias/list/',
  async (tabId: string, { getState }) => {
    if (tabId === undefined) {
      return;
    }
    const state = getState() as RootState;
    let url =
      process.env.REACT_APP_API_BASE_URL +
      'client/alias/list/' +
      tabId +
      '/' +
      state.user.externalId;
    try {
      const response = await AXIOS.get(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);

export const lookUpTabName = createAsyncThunk(
  'client/',
  async (newTabName: string, { getState }) => {
    const state = getState() as RootState;
    let url =
      process.env.REACT_APP_API_BASE_URL + 'client/' + newTabName + '/' + state.user.externalId;
    try {
      const response = await AXIOS.get(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);

export const lookUpTabNameAlias = createAsyncThunk(
  'client/alias/',
  async (tabNameAlias: any, { getState }) => {
    const state = getState() as RootState;
    let url =
      process.env.REACT_APP_API_BASE_URL +
      'client/alias/' +
      tabNameAlias +
      '/' +
      state.user.externalId;
    try {
      const response = await AXIOS.get(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);

export const createTabNameAlias = createAsyncThunk(
  'client/alias/',
  async (tabNameAliasInfo: any, { getState }) => {
    const state = getState() as RootState;
    let url = process.env.REACT_APP_API_BASE_URL + 'client/alias/' + state.user.externalId;
    try {
      const response = await AXIOS.post(url, tabNameAliasInfo);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);

export const createTabName = createAsyncThunk(
  'client/',
  async (clientName: string, { getState }) => {
    const state = getState() as RootState;
    const url = ADD_CLIENT.replace(API_CLIENT_NAME, clientName).replace(
      API_VARIABLE_USER_ID,
      state.user.externalId,
    );
    try {
      const response = await AXIOS.post(url, {});
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);

export const updateTabNameAlias = createAsyncThunk(
  'client/alias/',
  async (tabNameAliasInfo: any, { getState }) => {
    const state = getState() as RootState;
    let url =
      process.env.REACT_APP_API_BASE_URL +
      'client/alias/' +
      tabNameAliasInfo?.externalId +
      '/' +
      tabNameAliasInfo?.name +
      '/' +
      state.user.externalId;
    try {
      const response = await AXIOS.post(url, {});
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);

export const updateTabName = createAsyncThunk(
  'client/',
  async (updatedClientName: string, { getState }) => {
    const state = getState() as RootState;
    const url =
      process.env.REACT_APP_API_BASE_URL +
      'client/' +
      state.settings.selectedTabInfo.id +
      '/' +
      state.user.externalId;
    try {
      const response = await AXIOS.put(url, { name: updatedClientName });
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);

export const updateTabOrder = createAsyncThunk(
  'client/',
  async (orderInfo: {
    name: string,
    sourceViewOrder: Number,
    targetViewOrder: Number,
  }, { getState }) => {
    const state = getState() as RootState;
    const url =
      process.env.REACT_APP_API_BASE_URL +
      'client/' +
      state.settings.selectedTabInfo.id +
      '/' +
      state.user.externalId;
    try {
      const response = await AXIOS.put(url, orderInfo);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);

export const deleteTabName = createAsyncThunk(
  'client/delete',
  async (selectedClient: ClientEntity, { getState }) => {
    const state = getState() as RootState;
    const url =
      process.env.REACT_APP_API_BASE_URL +
      'client/' +
      selectedClient.id +
      '/' +
      state.user.externalId;
    try {
      const response = await AXIOS.delete(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);

export const deleteTabByMovingLOI = createAsyncThunk(
  'client/delete',
  async (targettedClient: ClientEntity, { getState }) => {
    const state = getState() as RootState;
    const url =
      process.env.REACT_APP_API_BASE_URL +
      'client/' +
      state.settings.selectedTabInfo.id +
      '/' +
      targettedClient.id +
      '/' +
      state.user.externalId;
    try {
      const response = await AXIOS.delete(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);

export const moveTabAlias = createAsyncThunk('client/alias', async (client: any, { getState }) => {
  const state = getState() as RootState;
  const url =
    process.env.REACT_APP_API_BASE_URL +
    'client/alias/' +
    client?.aliasTab +
    '/' +
    client?.targetTab +
    '/' +
    state.user.externalId;
  try {
    const response = await AXIOS.put(url);
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const deleteTabAliasName = createAsyncThunk(
  'client/delete',
  async (selectedClientAlias: ClientAlias, { getState }) => {
    const state = getState() as RootState;
    let url =
      process.env.REACT_APP_API_BASE_URL +
      'client/alias/' +
      selectedClientAlias.externalId +
      '/' +
      state.user.externalId;
    try {
      const response = await AXIOS.delete(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);

// Market Settings Related API

export const createMarket = createAsyncThunk(
  'market/create',
  async (marketName: string, { getState }) => {
    const state = getState() as RootState;
    let url =
      process.env.REACT_APP_API_BASE_URL +
      'market/' +
      state.user.externalId +
      '/' +
      marketName;
    try {
      const response = await AXIOS.post(url, {});
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);

export const updateMarket = createAsyncThunk(
  'market/update',
  async (marketInfo: MarketEntity, { getState }) => {
    const state = getState() as RootState;
    let url =
      process.env.REACT_APP_API_BASE_URL +
      'market/' +
      state.user.externalId +
      '/' +
      marketInfo.externalId +
      '/' +
      marketInfo.name;
    try {
      const response = await AXIOS.put(url, {});
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);

export const deleteMarket = createAsyncThunk(
  'market/delete',
  async (marketInfo: MarketEntity, { getState }) => {
    const state = getState() as RootState;
    let url =
      process.env.REACT_APP_API_BASE_URL +
      'market/' +
      state.user.externalId +
      '/' +
      marketInfo.externalId;
    try {
      const response = await AXIOS.delete(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);


// General Settings Related API

export const updateGeneralSettings = createAsyncThunk(
  'market/update',
  async (generalSettings: any, { getState }) => {
    const state = getState() as RootState;
    let url =
      process.env.REACT_APP_API_BASE_URL +
      'settings/' +
      state.user.externalId;
    try {
      const response = await AXIOS.put(url, generalSettings);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);