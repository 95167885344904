/**
 * Field DB Action
 */

import {
  FieldOfflineEntity,
  FieldUpdateOfflineEntity,
  FieldUpdatePayloadOffline,
} from "../common/types/DB";
import { FieldUpdatePayload } from "../common/types/EntityTypes";
import { db } from "./db";

export const fetchOfflineFields = () => {
  return db.fieldValues.toArray();
};

export const upsertFields = (values: FieldOfflineEntity[]) => {
  if (values) return db.fieldValues.bulkPut(values);
};

/**
 * Field Update Values
 * @param value
 * @returns
 */

export const upsertFieldValues = (value: FieldUpdateOfflineEntity) => {
  return db.fieldUpdateValues.put(value);
};

export const updateFieldMetadata = async (updatedData: FieldUpdatePayloadOffline) => {
  if (updatedData) {
    await db.transaction("rw", db.fieldValues, async () => {
      // Find the item based on the specific column value
      const itemToUpdate = await db.fieldValues
        .where("code")
        .equals(updatedData.code)
        .first();

      // Check if the item exists
      if (itemToUpdate) {
        // Update the item with the new data
        await db.fieldValues.update(itemToUpdate.id, {
          name: updatedData.name,
          order: updatedData.order,
          isPinned: updatedData.isPinned,
          isVisible: updatedData.isVisible,
          isPersisted: updatedData.isPersisted,
        });
      }
    });
  }
};

export const deleteField = (values: number[]) => {
  return db.fieldValues.bulkDelete(values);
};
