import moment from "moment";
import "./UploadTableCell.scss";
import {
  AP_STATUS,
  BACKEND_AP_STATUS,
  TOOL_TIP_UPLOAD,
  UPLOAD_HEADER,
} from "../../../../common/constants";
import { CustomUploadCellParams } from "../../../../common/types/dashboard/DashboardUITypes";
import classNames from "classnames";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  cancelDocumentProcess,
  updateDocumentsStatusDeleted,
} from "../../../../redux/reducers/documentsStatusSlice";
import {
  updateDocumentIsDeleted,
  updatePersistanceInStatusForDocumentID,
} from "../../../../db/documentStatusDBAction";
import { Tooltip } from "react-tooltip";
import { useState } from "react";
import { getUpdatedCustomTableFontSize } from '../../../../common/util';

function UploadTableCell(props: CustomUploadCellParams) {
  const dispatch = useAppDispatch();
  const [isTruncted, setIsTruncated] = useState(false);
  const customStyles = {
    fontStyle: 'normal',
    '--custom-document-name-font-size': getUpdatedCustomTableFontSize('--custom-document-name-font-size'),
    '--custom-document-name-other-font-size': getUpdatedCustomTableFontSize('--custom-document-name-other-font-size'),
    '--custom-upload-dialog-date-font-size': getUpdatedCustomTableFontSize('--custom-upload-dialog-date-font-size'),
    '--custom-upload-dialog-time-font-size': getUpdatedCustomTableFontSize('--custom-upload-dialog-time-font-size'),
  };
  const getCell = () => {
    const isProcessCompleted = () => props.data.percentageCompleted == 100;

    const getFontColor = () =>
      isProcessCompleted() ? "done-font-color" : "in-progress-font-color";

    const isCancellable = () =>
      props.data.percentageCompleted == 100
        ? "visible-hidden"
        : "visibilty-not-hiddent";

    const isError = () => props.data.apStatus == BACKEND_AP_STATUS.ERROR ? "visible-hidden"
    : "visibilty-not-hiddent";

    /*const handleCancel = () => {
      let docIds = localStorage.getItem(DELETE_ACTION_DOC_IDS);
      if (docIds != null) {
        const docIdList: string[] = JSON.parse(docIds);
        docIdList.push(props.data.id);
        localStorage.setItem(DELETE_ACTION_DOC_IDS, JSON.stringify(docIdList));
      } else {
        localStorage.setItem(
          DELETE_ACTION_DOC_IDS,
          JSON.stringify([props.data.id])
        );
      }

      if (props.data.percentageCompleted > 25) {
        dispatch(updateDocumentLeaseStatus(props.data.id));
      } else {
        dispatch(cancelDocumentProcess(props.data.id));
      }
    };*/

    const handleCancel = () => {
      updateDocumentIsDeleted(props.data.id, true, false)?.then(() => {
        dispatch(
          updateDocumentsStatusDeleted({
            documentId: props.data.id,
            isDeleted: true,
          })
        );

        dispatch(cancelDocumentProcess(props.data.id)).then(() => {
          updatePersistanceInStatusForDocumentID(props.data.id, true);
        });
      });
    };

    if (props.type == UPLOAD_HEADER.STATUS) {
      return (
        <div
          className={classNames(
            'flex',
            "document-name",
            "done-font-color",
            props.data.read ? "text-read" : "text-unread"
          )}
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          {props.data.apStatus == BACKEND_AP_STATUS.AP_COMPLETE
            ? AP_STATUS.DONE
            : props.data.apStatus}
        </div>
      );
    } else if (props.type == UPLOAD_HEADER.DOCUMENT_NAME) {
      return (
        <div
          className="flex flex-contain"
          style={{
            alignItems: "center",
            width: 'inherit'
          }}
        >
          <div
            title={props.data.fileName}
            style={{
              width: "80%",
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
            className={classNames(
              "document-name",
              "done-font-color",
              props.data.read ? "text-read" : "text-unread"
            )}
          >
            {props.data.fileName}
          </div>
          <div
            className={classNames("flex",isError())}
            style={{
              width: '15%',
              gap: "0.7rem",
              justifyContent: 'flex-end',
            }}
          >
            <a
              onClick={handleCancel}
              className={classNames(
                "bold-name-text",
                "done-font-color",
                isCancellable(),
                props.data.read ? "text-read" : "text-unread"
              )}
            >
              Cancel
            </a>
            <div
              className={classNames(
                "light-name-text",
                "done-font-color",
                props.data.read ? "text-read" : "text-unread"
              )}
            >
              {props.data.percentageCompleted}%
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="uploader-date-time">
          <div
            className={classNames(
              'flex',
              "uploaded-date",
              "done-font-color",
              props.data.read ? "text-read" : "text-unread"
            )}
          >
            {moment(props.data.lastActionAt).format("MMM DD, YYYY")} -
          </div>
          <div
            className={classNames(
              "uploaded-time",
              "done-font-color",
              props.data.read ? "text-read" : "text-unread"
            )}
          >
            {moment(props.data.lastActionAt).format("h:mma")}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-contain upload-table-cell-holder" style={customStyles}>
      {getCell()}
      <Tooltip className="tab-tooltip" id={TOOL_TIP_UPLOAD}></Tooltip>
    </div>
  );
}
export default UploadTableCell;
