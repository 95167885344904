/**
 * Markets Details
 */

import { MarketOfflineEntity } from "../common/types/DB";
import { db } from "./db";

export const fetchMarkets = () => {
  return db.marketValues.toArray();
};

export const upsertMarkets = (values: MarketOfflineEntity[]) => {
  if (values) return db.marketValues.bulkPut(values);
};

export const deleteMarkets = (values: string[]) => {
  return db.marketValues.bulkDelete(values);
};
