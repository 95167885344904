import './General.scss';
import {
  selectCellPadding,
  selectDefaultFontSize,
  selectFontSize,
  selectLineHeight,
  selectTableFontSize,
  setCustomCellPadding,
  setCustomFontSize,
  setCustomLineHeight,
  setCustomRowActiveHeight,
  setCustomRowInActiveHeight,
  setCustomTableFontSize,
} from '../../../../redux/reducers/uiSlice';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import rightArrow from './../../../../assets/images/dialog/settings/rightArrow.svg';
import leftArrow from './../../../../assets/images/dialog/settings/leftArrow.svg';
import grayedRightArrow from './../../../../assets/images/dialog/settings/grayedRightArrow.svg';
import grayedLeftArrow from './../../../../assets/images/dialog/settings/grayedLeftArrow.svg';
import plus from './../../../../assets/images/dialog/settings/plus.svg';
import minus from './../../../../assets/images/dialog/settings/minus.svg';
import grayedPlus from './../../../../assets/images/dialog/settings/grayedPlus.svg';
import grayedMinus from './../../../../assets/images/dialog/settings/grayedMinus.svg';
import {
  getCellPaddingSettings,
  getFontSizeSettings,
  getLineHeightSettings,
  getUpdatedCustomFontSize,
} from '../../../../common/util';
import {
  selectUserActiveRowHeight,
  selectUserAppFontSize,
  selectUserCellPadding,
  selectUserInactiveRowHeight,
  selectUserLineHeight,
  selectUserTableFontSize,
  setUserActiveRowHeight,
  setUserAppFontSize,
  setUserCellPaddingSize,
  setUserInActiveRowHeight,
  setUserLineHeightSize,
  setUserTableFontSize,
  updateGeneralSettings,
} from '../../../../redux/reducers/settingsSlice';
import { setSettings } from '../../../../redux/reducers/popupSlice';
import { SETTINGS_TAB_NAME } from '../../../../common/constants';
import classNames from 'classnames';

function GeneralSettings() {
  const dispatch = useAppDispatch();

  const uiAppFontSize = useAppSelector(selectFontSize);
  const uiTableFontSize = useAppSelector(selectTableFontSize);
  const uiDefaultFontSize = useAppSelector(selectDefaultFontSize);
  const uiDefaultLineHeight = useAppSelector(selectLineHeight);
  const uiDefaultCellPadding = useAppSelector(selectCellPadding);

  const userDefinedFontSize = useAppSelector(selectUserAppFontSize);
  const userDefinedTableSize = useAppSelector(selectUserTableFontSize);
  const userDefinedCellPadding = useAppSelector(selectUserCellPadding);
  const userDefinedLineHeight = useAppSelector(selectUserLineHeight);
  const userDefinedActiveRowHeight = useAppSelector(selectUserActiveRowHeight);
  const userDefinedInactiveRowHeight = useAppSelector(selectUserInactiveRowHeight);

  const [isSettingsModified, setSettingsModified] = useState(false);
  const fontSizeSettings = getFontSizeSettings();
  const cellPaddingMarks = getCellPaddingSettings();
  const lineHeightMarks = getLineHeightSettings();

  const [inputTableFontSize, setInputTableFontSize] = useState(
    Number(userDefinedTableSize.value) > 0
      ? Number(userDefinedTableSize.value)
      : uiTableFontSize || fontSizeSettings?.baseFontSize,
  );
  const [inputAppFontSize, setInputAppFontSize] = useState(
    Number(userDefinedFontSize.value) > 0
      ? Number(userDefinedFontSize.value)
      : uiAppFontSize || fontSizeSettings?.baseFontSize,
  );
  const [inputCellPaddingSize, setCellPaddingSize] = useState(Number(userDefinedCellPadding.value));

  const [inputLineHeightSize, setLineHeightSize] = useState(
    Number(userDefinedLineHeight.value) > 0
      ? Number(userDefinedLineHeight.value)
      : uiDefaultLineHeight || lineHeightMarks?.baseLineHeight,
  );

  const [activeRowHeight, setActiveRowHeight] = useState(
    Number(userDefinedActiveRowHeight.value) > 0 ? Number(userDefinedActiveRowHeight.value) : 10,
  );

  const [inactiveRowHeight, setInActiveRowHeight] = useState(
    Number(userDefinedInactiveRowHeight.value) > 0 ? Number(userDefinedInactiveRowHeight.value) : 2,
  );

  const sliderStyles = {
    track: {
      background: 'none',
      width: '100%',
    },
    handle: {
      width: '4px',
      height: '25px',
      bottom: '-1px',
      border: '1px solid #FBB040',
      background: '#FFB000',
      opacity: '1',
      borderRadius: '2.5px',
    },
    rail: {
      borderRadius: '2.506px',
      background: '#DCDCDC',
    },
  };
  const dotStyle = {
    border: '0px',
    bottom: '0px',
    width: '0.5px',
    height: '15px',
    borderRadius: '2px',
  };

  useEffect(() => {
    if (isSettingsModified) {
      dispatch(setCustomFontSize(inputAppFontSize));
    }
  }, [inputAppFontSize]);

  useEffect(() => {
    if (isSettingsModified) {
      dispatch(setCustomTableFontSize(inputTableFontSize));
    }
  }, [inputTableFontSize]);

  useEffect(() => {
    if (isSettingsModified) {
      dispatch(setCustomRowInActiveHeight(inactiveRowHeight));
    }
  }, [inactiveRowHeight]);

  useEffect(() => {
    if (isSettingsModified) {
      dispatch(setCustomRowActiveHeight(activeRowHeight));
    }
  }, [activeRowHeight]);

  useEffect(() => {
    if (isSettingsModified) {
      dispatch(setCustomCellPadding(inputCellPaddingSize));
    }
  }, [inputCellPaddingSize]);

  useEffect(() => {
    if (isSettingsModified) {
      dispatch(setCustomLineHeight(inputLineHeightSize));
    }
  }, [inputLineHeightSize]);

  const increase = (e: string) => {
    setSettingsModified(true);
    if (e === 'appFontSize' && inputAppFontSize < fontSizeSettings.max) {
      setInputAppFontSize((prev) => prev + 1);
    }
    if (e === 'tableFontSize' && inputTableFontSize < fontSizeSettings.max) {
      setInputTableFontSize((prev) => prev + 1);
    }
    if (e === 'cellPadding' && inputCellPaddingSize < cellPaddingMarks.max) {
      setCellPaddingSize((prev) => prev + 5);
    }
    if (e === 'lineHeight' && inputLineHeightSize < lineHeightMarks.max) {
      setLineHeightSize((prev) => prev + 0.25);
    }
    if (e === 'activeRowHeight' && activeRowHeight < 25) {
      setActiveRowHeight((prev) => prev + 1);
    }
    if (e === 'inactiveRowHeight' && inactiveRowHeight < 25) {
      setInActiveRowHeight((prev) => prev + 1);
    }
  };

  const decrease = (e: string) => {
    setSettingsModified(true);
    if (e === 'appFontSize' && inputAppFontSize > fontSizeSettings.min) {
      setInputAppFontSize((prev) => prev - 1);
    }
    if (e === 'tableFontSize' && inputTableFontSize > fontSizeSettings.min) {
      setInputTableFontSize((prev) => prev - 1);
    }
    if (e === 'cellPadding' && inputCellPaddingSize > cellPaddingMarks.min) {
      setCellPaddingSize((prev) => prev - 5);
    }
    if (e === 'lineHeight' && inputLineHeightSize > lineHeightMarks.min) {
      setLineHeightSize((prev) => prev - 0.25);
    }
    if (e === 'activeRowHeight' && activeRowHeight >= 1) {
      setActiveRowHeight((prev) => prev - 1);
    }
    if (e === 'inactiveRowHeight' && inactiveRowHeight >= 1) {
      setInActiveRowHeight((prev) => prev - 1);
    }
  };

  const handleChange = (e: any, itemToChange: string) => {
    setSettingsModified(true);
    if (itemToChange === 'appFontSize') {
      setInputAppFontSize(e);
    }
    if (itemToChange === 'tableFontSize') {
      setInputTableFontSize(e);
    }
    if (itemToChange === 'cellPadding') {
      setCellPaddingSize(e);
    }
    if (itemToChange === 'lineHeight') {
      setLineHeightSize(e);
    }
    if (itemToChange === 'activeRowHeight' && e.target.value < 26 && e.target.value > 1) {
      setActiveRowHeight(e.target.value);
    }
    if (itemToChange === 'inactiveRowHeight' && e.target.value < 26 && e.target.value > 1) {
      setInActiveRowHeight(e.target.value);
    }
  };

  const handleApply = () => {
    if (inputAppFontSize !== uiDefaultFontSize || isSettingsModified) {
      dispatch(
        updateGeneralSettings({
          ...userDefinedFontSize,
          value: inputAppFontSize,
        }),
      ).then((res) => {
        if (res.payload?.data) {
          dispatch(setUserAppFontSize({ ...userDefinedFontSize, value: inputAppFontSize }));
        }
      });
    }

    if (inputTableFontSize !== uiDefaultFontSize || isSettingsModified) {
      dispatch(
        updateGeneralSettings({
          ...userDefinedTableSize,
          value: inputTableFontSize,
        }),
      ).then((res) => {
        if (res.payload?.data) {
          dispatch(setUserTableFontSize({ ...userDefinedTableSize, value: inputTableFontSize }));
        }
      });
    }

    if (inputCellPaddingSize !== uiDefaultCellPadding || isSettingsModified) {
      dispatch(
        updateGeneralSettings({
          ...userDefinedCellPadding,
          value: inputCellPaddingSize,
        }),
      ).then((res) => {
        if (res.payload?.data) {
          dispatch(
            setUserCellPaddingSize({ ...userDefinedCellPadding, value: inputCellPaddingSize }),
          );
        }
      });
    }

    if (inputLineHeightSize !== uiDefaultLineHeight || isSettingsModified) {
      dispatch(
        updateGeneralSettings({
          ...userDefinedLineHeight,
          value: inputLineHeightSize,
        }),
      ).then((res) => {
        if (res.payload?.data) {
          dispatch(setUserLineHeightSize({ ...userDefinedLineHeight, value: inputLineHeightSize }));
        }
      });
    }

    dispatch(
      updateGeneralSettings({
        ...userDefinedActiveRowHeight,
        value: activeRowHeight,
      }),
    ).then((res) => {
      if (res.payload?.data) {
        dispatch(setCustomRowActiveHeight(activeRowHeight));
        dispatch(setUserActiveRowHeight({ ...userDefinedActiveRowHeight, value: activeRowHeight }));
      }
    });

    dispatch(
      updateGeneralSettings({
        ...userDefinedInactiveRowHeight,
        value: inactiveRowHeight,
      }),
    ).then((res) => {
      if (res.payload?.data) {
        dispatch(setCustomRowActiveHeight(inactiveRowHeight));
        dispatch(
          setUserInActiveRowHeight({ ...userDefinedInactiveRowHeight, value: inactiveRowHeight }),
        );
      }
    });
    dispatch(setSettings(false));
  };

  const handleCancel = () => {
    setInputTableFontSize(Number(userDefinedTableSize.value));
    dispatch(setCustomFontSize(inputTableFontSize));
    setInputAppFontSize(Number(userDefinedFontSize.value));
    dispatch(setCustomTableFontSize(inputAppFontSize));
    setCellPaddingSize(Number(userDefinedCellPadding.value));
    dispatch(setCustomCellPadding(inputCellPaddingSize));
    setCustomLineHeight(Number(userDefinedLineHeight.value));
    dispatch(setCustomLineHeight(inputLineHeightSize));
    setActiveRowHeight(Number(userDefinedActiveRowHeight.value));
    dispatch(setCustomRowActiveHeight(activeRowHeight));
    setInActiveRowHeight(Number(userDefinedInactiveRowHeight.value));
    dispatch(setCustomRowInActiveHeight(inactiveRowHeight));
    setTimeout(() => {
      setSettingsModified(false);
    }, 300);
  };

  const handleClose = () => {
    dispatch(setSettings(false));
  };

  const handleResetToDefault = () => {
    setSettingsModified(true);
    setInputAppFontSize(12.5);
    setInputTableFontSize(12.5);
    setActiveRowHeight(11);
    setInActiveRowHeight(2);
    setCellPaddingSize(10);
    setLineHeightSize(1.5);
  };

  const customStyle = {
    gap: '1rem',
    '--custom-gs-label-font-size': getUpdatedCustomFontSize('--custom-gs-label-font-size'),
    '--custom-close-font-size': getUpdatedCustomFontSize('--custom-close-font-size')
  };

  return (
    <div className='gs-container' style={customStyle}>
      <div className='gs-container-header-text'>
        <span className='settings-content-header-text'>{SETTINGS_TAB_NAME.GENERAL}</span>
      </div>
      <div className='gs-controls-container'>
        <div className='gs-group'>
          <div className='gs-label'>Application Text Size:</div>
          <div className='gs-slider'>
            <Slider
              min={fontSizeSettings.min}
              max={fontSizeSettings.max}
              step={1}
              value={inputAppFontSize}
              dots
              marks={fontSizeSettings.range}
              onChange={(e) => handleChange(e, 'appFontSize')}
              styles={sliderStyles}
              dotStyle={dotStyle}
            />
          </div>
          <div className='gs-controls'>
            <img
              className={classNames({
                'events-disabled': fontSizeSettings.min === inputAppFontSize,
              })}
              src={fontSizeSettings.min != inputAppFontSize ? leftArrow : grayedLeftArrow}
              alt='<'
              onClick={() => decrease('appFontSize')}
            />
            <img
              className={classNames({
                'events-disabled': fontSizeSettings.max === inputAppFontSize,
              })}
              src={fontSizeSettings.max != inputAppFontSize ? rightArrow : grayedRightArrow}
              alt='>'
              onClick={() => increase('appFontSize')}
            />
          </div>
        </div>
        <div className='gs-group'>
          <div className='gs-label'>Table Content Text Size:</div>
          <div className='gs-slider'>
            <Slider
              min={fontSizeSettings.min}
              max={fontSizeSettings.max}
              step={1}
              value={inputTableFontSize}
              dots
              marks={fontSizeSettings.range}
              onChange={(e) => handleChange(e, 'tableFontSize')}
              styles={sliderStyles}
              dotStyle={dotStyle}
            />
          </div>
          <div className='gs-controls'>
            <img
              className={classNames({
                'events-disabled': fontSizeSettings.min === inputTableFontSize,
              })}
              src={fontSizeSettings.min != inputTableFontSize ? leftArrow : grayedLeftArrow}
              alt='<'
              onClick={() => decrease('tableFontSize')}
            />
            <img
              className={classNames({
                'events-disabled': fontSizeSettings.max === inputTableFontSize,
              })}
              src={fontSizeSettings.max != inputTableFontSize ? rightArrow : grayedRightArrow}
              alt='>'
              onClick={() => increase('tableFontSize')}
            />
          </div>
        </div>
        <div className='gs-group'>
          <div className='gs-label'>Cell Padding:</div>
          <div className='gs-slider gs-slider-small'>
            <Slider
              min={cellPaddingMarks.min}
              max={cellPaddingMarks.max}
              step={5}
              value={inputCellPaddingSize}
              dots
              marks={cellPaddingMarks.range}
              onChange={(e) => handleChange(e, 'cellPadding')}
              styles={sliderStyles}
              dotStyle={dotStyle}
            />
          </div>
          <div className='gs-controls'>
            <img
              className={classNames({
                'events-disabled': cellPaddingMarks.min === inputCellPaddingSize,
              })}
              src={cellPaddingMarks.min != inputCellPaddingSize ? leftArrow : grayedLeftArrow}
              alt='<'
              onClick={() => decrease('cellPadding')}
            />
            <img
              className={classNames({
                'events-disabled': cellPaddingMarks.max === inputCellPaddingSize,
              })}
              src={cellPaddingMarks.max != inputCellPaddingSize ? rightArrow : grayedRightArrow}
              alt='>'
              onClick={() => increase('cellPadding')}
            />
          </div>
        </div>
        <div className='gs-group'>
          <div className='gs-label'>Line Spacing:</div>
          <div className='gs-slider gs-slider-small'>
            <Slider
              min={lineHeightMarks.min}
              max={lineHeightMarks.max}
              step={0.25}
              value={inputLineHeightSize}
              dots
              marks={lineHeightMarks.range}
              onChange={(e) => handleChange(e, 'lineHeight')}
              styles={sliderStyles}
              dotStyle={dotStyle}
            />
          </div>
          <div className='gs-controls'>
            <img
              className={classNames({
                'events-disabled': lineHeightMarks.min === inputLineHeightSize,
              })}
              src={lineHeightMarks.min != inputLineHeightSize ? leftArrow : grayedLeftArrow}
              alt='<'
              onClick={() => decrease('lineHeight')}
            />
            <img
              className={classNames({
                'events-disabled': lineHeightMarks.max === inputLineHeightSize,
              })}
              src={lineHeightMarks.max != inputLineHeightSize ? rightArrow : grayedRightArrow}
              alt='>'
              onClick={() => increase('lineHeight')}
            />
          </div>
        </div>
        <div className='gs-group gs-control-input'>
          <div className='gs-label'>Height of Active Row:</div>
          <div className='gs-input'>
            <input
              type='number'
              min={2}
              max={50}
              value={activeRowHeight}
              onChange={(e) => handleChange(e, 'activeRowHeight')}
              className='gs-input-small'
            />
            <div className='gs-controls'>
              <img
                className={classNames({
                  'events-disabled': activeRowHeight < 2,
                })}
                src={activeRowHeight < 2 ? grayedMinus : minus}
                alt='-'
                onClick={() => decrease('activeRowHeight')}
              />
              <img
                className={classNames({
                  'events-disabled': activeRowHeight > 26,
                })}
                src={activeRowHeight > 26 ? grayedPlus : plus}
                alt='+'
                onClick={() => increase('activeRowHeight')}
              />
            </div>
            <div className='gs-controls-legend'>Lines of Text</div>
          </div>
        </div>
        <div className='gs-group gs-control-input'>
          <div className='gs-label'>Height of Inactive Row:</div>
          <div className='gs-input'>
            <input
              type='number'
              min={1}
              max={25}
              value={inactiveRowHeight}
              onChange={(e) => handleChange(e, 'inactiveRowHeight')}
              className='gs-input-small'
            />
            <div className='gs-controls'>
              <img
                className={classNames({
                  'events-disabled': inactiveRowHeight < 2,
                })}
                src={inactiveRowHeight < 2 ? grayedMinus : minus}
                alt='-'
                onClick={() => decrease('inactiveRowHeight')}
              />
              <img
                className={classNames({
                  'events-disabled': inactiveRowHeight > 26,
                })}
                src={inactiveRowHeight > 26 ? grayedPlus : plus}
                alt='+'
                onClick={() => increase('inactiveRowHeight')}
              />
            </div>
            <div className='gs-controls-legend'>Lines of Text</div>
          </div>
        </div>
      </div>
      <div className='gs-reset-holder'>
        <button className='settings-button gs-reset-btn' onClick={handleResetToDefault}>
          <span className='settings-button-text'>Reset to Default Values</span>
        </button>
      </div>
      <div className='gs-button-container'>
        <button
          disabled={!isSettingsModified}
          className={classNames('settings-button', {
            'button-orange': isSettingsModified,
          })}
          onClick={handleApply}>
          <span className='settings-button-text'>&nbsp;&nbsp;Apply&nbsp;</span>
        </button>
        {!isSettingsModified && (
          <button className='settings-button' onClick={handleClose}>
            <span className='settings-button-text'>Close</span>
          </button>
        )}
        {isSettingsModified && (
          <button className='settings-button' onClick={handleCancel}>
            <span className='settings-button-text'>Cancel</span>
          </button>
        )}
      </div>
    </div>
  );
}
export default GeneralSettings;
