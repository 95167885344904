import Save from '../../../../assets/images/dialog/settings/SettingsSave.svg';
import GrayedSave from '../../../../assets/images/dialog/settings/grayedSave.svg';
import { SETTINGS_TAB_HEADER_SUB_TEXT, SETTINGS_TAB_NAME } from '../../../../common/constants';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { fetchUserMarkets, selectMarkets } from '../../../../redux/reducers/metadataSlice';
import Delete from '../../../../assets/images/dialog/settings/Delete.svg';
import blackDelete from '../../../../assets/images/dialog/settings/deleteBlack.svg';
import './MarketSettings.scss';
import classNames from 'classnames';
import { getUpdatedCustomFontSize, getUpdatedLineHeight } from '../../../../common/util';
import { useState } from 'react';
import { createMarket, deleteMarket, updateMarket } from '../../../../redux/reducers/settingsSlice';
import { selectUserID } from '../../../../redux/reducers/userSlice';
import { setSettings } from '../../../../redux/reducers/popupSlice';
import { MarketEntity } from '../../../../common/types/EntityTypes';

function MarketSettings() {
  const markets = useAppSelector(selectMarkets);
  const dispatch = useAppDispatch();
  const userId = useAppSelector(selectUserID);
  const customStyle = {
    fontStyle: 'normal',
    '--custom-market-input-font-size': getUpdatedCustomFontSize('--custom-market-input-font-size'),
    '--custom-market-creator-header-subtitle': getUpdatedCustomFontSize('--custom-market-creator-header-subtitle'),
    '--custom-settings-font-size': getUpdatedCustomFontSize('--custom-settings-font-size'),
    '--custom-settings-line-height': getUpdatedLineHeight(),
    '--custom-close-font-size': getUpdatedCustomFontSize('--custom-close-font-size')
  };
  const [newMarketValue, setNewMarketValue] = useState('');
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMarketValue(e.target.value);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Escape') handleCancel();
    else if (event.key === 'Enter' && newMarketValue !== '') handleCreateMarket();
  };

  const handleCancel = () => {
    setNewMarketValue('');
    dispatch(setSettings(false));
  };

  const handleCreateMarket = () => {
    dispatch(createMarket(newMarketValue)).then((res) => {
      if (res?.payload?.data) {
        dispatch(fetchUserMarkets(userId.toString()));
        setNewMarketValue('');
      }
    });
  };

  const [isEditing, setIsEditing] = useState(false);
  const [updatedMarketValue, setUpdatedMarketValue] = useState('');
  const [selectedMarket, setSelectedMarket] = useState({} as MarketEntity);

  const handleMarketUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedMarketValue(e.target.value);
  };

  const handleMarketUpdateKeyDown = (event: any) => {
    if (event.key === 'Escape') handleMarketUpdateCancel(selectedMarket);
    else if (event.key === 'Enter' && updatedMarketValue !== '')
      handleMarketUpdateSubmit(selectedMarket);
  };

  const handleMarketUpdateCancel = (market: MarketEntity) => {
    setUpdatedMarketValue('');
    setSelectedMarket({} as MarketEntity);
    setIsEditing(false);
  };

  const handleMarketUpdateSubmit = (market: MarketEntity) => {
    dispatch(updateMarket({ ...market, name: updatedMarketValue })).then((res) => {
      if (res?.payload?.data) {
        dispatch(fetchUserMarkets(userId.toString()));
        setSelectedMarket({} as MarketEntity);
        setIsEditing(false);
      }
    });
  };

  const handleMarketDelete = (market: MarketEntity) => {
    dispatch(deleteMarket(market)).then((res) => {
      if (res?.payload?.data) {
        dispatch(fetchUserMarkets(userId.toString()));
      }
    });
  };

  const handleDoubleClick = (market: MarketEntity) => {
    setIsEditing(true);
    setSelectedMarket(market);
    setUpdatedMarketValue(market?.name);
  };

  return (
    <div className='flex-basis-column' style={customStyle}>
      <div className='market-header'>
        <div className='settings-content-header-text-holder'>
          <span className='settings-content-header-text'>{SETTINGS_TAB_NAME.MARKET}</span>
        </div>
        <div className='settings-content-header-text-holder'>
          <span className='settings-content-sub-header-text'>
            {SETTINGS_TAB_HEADER_SUB_TEXT.MARKET}
          </span>
        </div>
      </div>
      <div className='market-grid'>
        {markets.map((market, index) => {
          return (
            <div
              className={classNames('market-item', 'flex', {
                'market-edit-enabled': selectedMarket.externalId == market.externalId,
              })}
              style={customStyle}>
              <div key={market?.externalId} className='market-container'>
                {isEditing && selectedMarket.externalId == market.externalId ? (
                  <div className={classNames('market-input-group')}>
                    <input
                      onKeyDown={handleMarketUpdateKeyDown}
                      name={'market-' + market?.name}
                      className='market-input-name'
                      onChange={handleMarketUpdate}
                      value={updatedMarketValue}
                    />
                    <img
                      className='market-tools'
                      alt='Delete'
                      src={blackDelete}
                      onClick={() => handleMarketDelete(market)}
                    />
                  </div>
                ) : (
                  <section
                    className='market-item-display-holder'
                    onDoubleClick={() => handleDoubleClick(market)}>
                    <div className='market-display-name'>{market.name}</div>
                    <img
                      className='market-tools'
                      alt='Delete'
                      src={Delete}
                      onClick={() => handleMarketDelete(market)}
                    />
                  </section>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className='flex' id='new-market'>
        <span className='market-creator-header-text'>
          Add a New Market &nbsp;{' '}
          <span className='market-creator-header-subtitle'>(30 characters max)</span>
        </span>

        <div className='market-input-container' style={{ gap: '5px' }}>
          <input
            onKeyDown={handleKeyDown}
            type='text'
            className='market-input-box'
            onChange={handleInputChange}
            value={newMarketValue}
            maxLength={30}
          />
          <img
            className={classNames('market-add-tools', {
              'events-disabled': newMarketValue === '',
            })}
            src={newMarketValue !== '' ? Save : GrayedSave} onClick={handleCreateMarket}></img>
        </div>
      </div>
      <div className='ms-btn-container'>
        <button className='settings-button' onClick={handleCancel}>
          <span className='settings-button-text'>Close</span>
        </button>
      </div>
    </div>
  );
}

export default MarketSettings;
