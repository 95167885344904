import {
  TypedAddListener,
  TypedStartListening,
  addListener,
  createListenerMiddleware,
} from "@reduxjs/toolkit";
import { setPing } from "./reducers/appSlice";
import { AppDispatch, RootState } from "./store";
import { addDocumentStatus } from "./reducers/documentsStatusSlice";

export const listenerMiddleware = createListenerMiddleware();

type AppStartListening = TypedStartListening<RootState, AppDispatch>;

const startAppListening =
  listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<
  RootState,
  AppDispatch
>;

startAppListening({
  actionCreator: setPing,
  effect: async (action) => {},
});

startAppListening({
  actionCreator: addDocumentStatus,
  effect: async (action) => {
    addDocumentStatus(action.payload);
  },
});
