import classNames from 'classnames';
import { useState } from 'react';
import { SETTINGS_TAB_NAME } from '../../../common/constants';
import { ImageMetadata } from '../../../common/types/dashboard/DashboardUITypes';
import { useAppSelector } from '../../../redux/hooks';
import { selectUploadDialogLogoMetadata } from '../../../redux/reducers/uiSlice';
import './Settings.scss';
import MarketSettings from './market-settings/MarketSettings';
import GeneralSettings from './general-settings/General';
import { getUpdatedCustomFontSize } from '../../../common/util';
import DeleteConfirmDialog from './tab-settings/tab-modal/DeleteConfirmDialog';
import { selectShowTabSettingsModal } from '../../../redux/reducers/settingsSlice';
import TabListDialog from './tab-settings/tab-modal/TabListDialog';
import TabMessage from './tab-settings/tab-modal/TabMessageDialog';

function Settings() {
  const logo: ImageMetadata = useAppSelector(selectUploadDialogLogoMetadata);
  const [selectedSettings, setSelectedSettings] = useState(SETTINGS_TAB_NAME.GENERAL);
  const showTabSettingsModal = useAppSelector(selectShowTabSettingsModal);
  const customStyle = {
    fontWeight: 'normal',
    '--custom-settings-font-size': getUpdatedCustomFontSize('--custom-settings-font-size'),
  };

  return (
    <div className='settings-holder' style={customStyle}>
      <div className='flex flex-align-end'>
        <img src={logo.image}></img>
      </div>
      <div className='flex settings-tab-content-container'>
        <div className='flex settings-tab-container'>
          {Object.values(SETTINGS_TAB_NAME).map((value) => {
            return (
              <div
                key={value}
                className={classNames(
                  'setting-tab',
                  selectedSettings == value
                    ? 'setting-tab-text-selected'
                    : 'setting-tab-text-not-selected',
                )}
                style={{ cursor: 'pointer' }}
                onClick={() => setSelectedSettings(value)}>
                <span className='setting-tab-text'>{value}</span>
              </div>
            );
          })}
        </div>
        <div className='flex flex-basis settings-content-container'>
          {selectedSettings == SETTINGS_TAB_NAME.MARKET && (
            <MarketSettings key={1}></MarketSettings>
          )}
          {selectedSettings == SETTINGS_TAB_NAME.GENERAL && (
            <GeneralSettings key={2}></GeneralSettings>
          )}
          {/* {selectedSettings == SETTINGS_TAB_NAME.TAB && <TabSettings key={3}></TabSettings>} */}
        </div>
      </div>
      <div>
        {showTabSettingsModal === 'deleteTab' && <DeleteConfirmDialog></DeleteConfirmDialog>}
        {showTabSettingsModal === 'moveTabAlias' && <TabListDialog></TabListDialog>}
        {showTabSettingsModal === 'tabMessage' && <TabMessage isActionable={false} ></TabMessage>}
        {showTabSettingsModal === 'tabMessageActionable' && <TabMessage isActionable={true}></TabMessage>}
      </div>
    </div>
  );
}

export default Settings;
