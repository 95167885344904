import {
  AuthorOfflineEntity,
  ClientOfflineEntity,
  DocumentOfflineEntity,
  DocumentStatusOfflineEntity,
  FieldOfflineEntity,
  FieldUpdateOfflineEntity,
  FieldUpdatePayloadOffline,
  FieldValues,
  MarketOfflineEntity,
} from "./DB";
import {
  AuthorEntity,
  ClientEntity,
  ClientServerDTO,
  DocumentEntity,
  FieldEntity,
  FieldUpdatePayload,
  MarketEntity,
} from "./EntityTypes";
import { DocumentsStatusState } from "./SliceTypes";

export const mapOfflineAuthorEntityToAuthorEntity = (
  entity: AuthorOfflineEntity
): AuthorEntity => {
  return { ...entity } as AuthorEntity;
};

export const mapAuthorEntityToOfflineAuthorEntity = (
  entity: AuthorEntity,
  isPersisted: boolean
): AuthorOfflineEntity => {
  return {
    ...entity,
    isPersisted,
  };
};

export const mapOfflineMarketEntityToMarketEntity = (
  entity: MarketOfflineEntity
): MarketEntity => {
  return { ...entity } as MarketEntity;
};

export const mapMarketEntityToOfflineMarketEntity = (
  entity: MarketEntity,
  isPersisted: boolean
): MarketOfflineEntity => {
  return {
    ...entity,
    isPersisted,
  };
};

export const mapDSOfflineEntityToDSEntity = (
  entity: DocumentStatusOfflineEntity
): DocumentsStatusState => {
  return { ...entity } as DocumentsStatusState;
};

export const mapDocumentStatusStateToOfflineEntity = (
  entity: DocumentsStatusState,
  isPersisted: boolean
): DocumentStatusOfflineEntity => {
  return {
    ...entity,
    isPersisted,
  };
};

export const mapOfflineFieldEntityToFieldEntity = (
  entity: FieldOfflineEntity
): FieldEntity => {
  return {
    ...entity,
  } as FieldEntity;
};

export const mapFieldEntityToOfflineFieldEntity = (
  entity: FieldEntity,
  isPersisted: boolean
): FieldOfflineEntity => {
  return {
    ...entity,
    isPersisted,
  };
};

export const mapFieldUpdatePayloadToOfflineFieldUpdate = (
  entity: FieldUpdatePayload,
  isPersisted: boolean
): FieldUpdatePayloadOffline => {
  return {
    ...entity,
    isPersisted,
  };
};

export const mapFieldUpdateToOffline = (
  entity: FieldValues,
  isPersisted: boolean
): FieldUpdateOfflineEntity => {
  return {
    ...entity,
    isPersisted,
  };
};

export const mapOfflineDocumentEntityToDocumentEntity = (
  entity: DocumentOfflineEntity
): DocumentEntity => {
  return {
    ...entity,
  } as DocumentEntity;
};

export const mapDocumentEntityToOfflineEntity = (
  entity: DocumentEntity,
  isPersisted: boolean
): DocumentOfflineEntity => {
  return {
    ...entity,
    isPersisted,
  };
};

export const mapOfflineClientEntityToClientEntity = (
  entity: ClientOfflineEntity
): ClientEntity => {
  return { ...entity } as ClientEntity;
};

export const mapClientEntityToOfflineClientEntity = (
  entity: ClientEntity,
  isPersisted: boolean
) => {
  return {
    ...entity,
    isPersisted: isPersisted,
  };
};

export const mapClientServerDTOToOfflineEntity = (
  entity: ClientServerDTO,
  isPersisted: boolean
): ClientOfflineEntity => {
  return {
    id: entity.externalId,
    custom: entity.custom,
    name: entity.name,
    viewOrder: entity.viewOrder,
    alias: [],
    isPersisted: isPersisted,
    default: entity.default,
  };
};
